import Rails from "@rails/ujs"

$(function() {
  function initCalculation() {
    var js_expenses_per_month_error = $('.js-expenses-per-month-error'),
      js_expenses_per_month = $('.js-expenses-per-month'),
      spending_profile = ["name", "home", "restaurant", "rest", "health", "travel", "car", "supermarket", "credit"];

    $('.js-to-spending-profile').on('click', function () {
      if (parseInt(js_expenses_per_month.val()) > 0) {

        $('.js-spending-profile').show();
        js_expenses_per_month_error.hide();
        $(this).hide();
      } else {
        js_expenses_per_month_error.show();
      }
    });

    js_expenses_per_month.on('keyup', function () {
      if (parseInt($(this).val()) > 0) {
        js_expenses_per_month_error.hide();
      } else {
        js_expenses_per_month_error.show();
      }
    });

    $('.js-spending-profile-item').on('click', function () {
      $('.js-spending-profile-item.bg-info').removeClass('bg-info');
      $(this).addClass('bg-info');

      setSpendingProfile($(this));

      $('.js-spending-profile-values').show();
    });

    function setSpendingProfile(card) {
      for (var profile of spending_profile) {
        $('.js-' + profile).val(card.attr('data-' + profile));
        $('.js-' + profile + '-label').text(card.attr('data-' + profile));
      }
    }

    $('.js-profile-plus').on('click', function () {
      spendingPlusMinus($(this), '+');
    });

    $('.js-profile-minus').on('click', function () {
      spendingPlusMinus($(this), '-');
    });

    function spendingPlusMinus(button, operation) {
      var input = $(button.attr('data-input')),
        label = $(button.attr('data-label')),
        val = input.val();

      if (operation === '+') {
        val++;
      } else {
        val--;
      }

      if (val > 100) {
        val = 100
      }

      if (val < 0) {
        val = 0
      }

      input.val(val);
      label.text(val);
    }

    $('.js-to-calculation').on('click', function () {
      var btn = $(this);

      Rails.ajax({
        type: "POST",
        url: "/benefits",
        beforeSend(xhr, options) {
          xhr.setRequestHeader('Content-Type', 'application/json; charset=UTF-8')
          // Workaround: add options.data late to avoid Content-Type header to already being set in stone
          // https://github.com/rails/rails/blob/master/actionview/app/assets/javascripts/rails-ujs/utils/ajax.coffee#L53
          options.data = getSpendingDataJSON()
          return true
        },
        success: function (response) {
          btn.hide();
        },
        error: function (response) {
          alert('Произошла ошибка, попробуйте позже.');
        }
      });
    });

    function getSpendingDataJSON() {
      var data = {}

      data["expenses_per_month"] = js_expenses_per_month.val();

      for (var profile of spending_profile) {
        data[profile] = $('.js-' + profile).val();
      }

      return JSON.stringify({spending_data: data});
    }
  }

  if ($('.js-calculation-form').length) {
    initCalculation();
  }
});

// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
require("jquery")
require("bootstrap")
require("trix")
require("@rails/actiontext")
require("./calculation.js")

Rails.start()
ActiveStorage.start()

import $ from 'jquery'
window.jQuery = $;
window.$ = $;

// const images = require.context('../images', true);
// const imagePath = (name) => images(name, true);

import "../stylesheets/all.sass"
